import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { questions, answer_1, answer_2, answer_3, answer_4, answer_5,
         answer_6, answer_7, answer_8 } from '../variables/assessments/csq8.js'
import { calculateTimepoint1 } from '../../functions/timepoint.js'

import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'

import StepperAssm from './stepper_assm'

class CSQ8 extends Component {
  constructor() {
      super()
      this.state = {
          status: 1,
          message: '',
          start_time: Math.floor(new Date().getTime()/1000),
          start_datetime: new Date().toLocaleString('en-US', { hour12: false }).slice(0, 19).replace('T', ' '),
          next_step: 'complete_assessments',
          q1: -1,
          q2: -1,
          q3: -1,
          q4: -1,
          q5: -1,
          q6: -1,
          q7: -1,
          q8: -1,
          comments: '',
      }
  }

  componentDidMount() {
    this.getAssmProcess()
    //console.log(this.state.start_datetime)
  }

  getAssmProcess = () => {
    const type = this.props.match.params.type
    const timepoint = this.props.match.params.timepoint
    if(type == 'sm' || timepoint == -2) {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/chk_each_assm',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          type: this.props.match.params.type,
          timepoint: this.props.match.params.timepoint,
          assm: 'patients_assessment_csq8',
         }
      })
      .then((result) => {
        console.log(result['data'])
        var calTimepoint = calculateTimepoint1(result['data'].timeline_start)
        if(this.props.match.params.timepoint > calTimepoint.current_month) {
          this.props.onLoadLocation('assessment_list_2', 'Sorry, something went wrong! Please only select available assessment in this page.')
          this.props.history.push('/member/assessment_list')
        } else if(this.props.match.params.timepoint == calTimepoint.current_month) {
          // Current timepoint => Fill the form //
          if(result['data'].status !== null) {
            if(result['data'].assm_status == 1) {
              // Completed //
              this.getCompletedData(2)
            } else {
              if(result['data'].status == 1 && result['data'].step !== 'csq8') {
                this.props.history.push('/member/assessment/'+result['data'].step+'/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
              }
            }
          } else {
            // Back up overview //
            this.props.history.push('/member/assessment/overview/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
          }
        } else {
          // Past timepoint //
          this.getCompletedData(3)
        }
      })
    } else {

    }
  }

  getCompletedData = (status) => {
    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_assm_data',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        type: this.props.match.params.type,
        timepoint: this.props.match.params.timepoint,
        frm: 'csq8',
       }
    }).then((result) => {
      if(result['data'].status == 1) {
        this.setState({
          status: status,
          q1: result['data'].q1,
          q2: result['data'].q2,
          q3: result['data'].q3,
          q4: result['data'].q4,
          q5: result['data'].q5,
          q6: result['data'].q6,
          q7: result['data'].q7,
          q8: result['data'].q8,
          comments: result['data'].comments,
        })
      }

      //console.log(this.state)
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var timepoint = this.props.match.params.timepoint
    var type = this.props.match.params.type
    var next_step = 'complete_assessments'
    if(this.state.status === 1) {
      if(this.state.q1 === -1 || this.state.q2 === -1 || this.state.q3 === -1 ||
        this.state.q4 === -1 || this.state.q5 === -1 || this.state.q6 === -1 ||
        this.state.q7 === -1 || this.state.q8 === -1) {
        this.setState({
          message: 'Please select all answers ',
        })
        window.scrollTo(0, 0)
      } else {
        axios({
          method: 'post',
          url: connectNode + 'patient/assessment/add_csq8',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            user: this.state,
            type: type,
            timepoint: this.props.match.params.timepoint,
          }
        })
        .then((result) => {
          //console.log(result['data'])
            if(result['data'] == next_step) {
              this.props.onLoadLocation('/member/assessment/'+result['data']+'/'+type+'/'+timepoint)
              this.props.history.push('/member/assessment/'+result['data']+'/'+type+'/'+timepoint)
            }
          }
        )
      }
    } else {
      this.props.onLoadLocation('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
      this.props.history.push('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
    }
  }

  render() {
    //console.log(this.state)
    const getAnswer = [answer_1, answer_2, answer_3, answer_4, answer_5, answer_6, answer_7, answer_8]
    return (
      <div className="assm_phq9 box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Client Satisfaction Questionnaire-8
          </div>
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="small-12 columns box2_2">
          Please help us improve our program by answering some questions about the services you hve received. We are interested in your honest opinions, whether they are positive or negative. Please answer all of the questions. We also welcome your comments and suggestions. Thank you very much. We appreciate your help.
          </div>
          <div className="small-12 columns box1">
          {questions.map(eachQ => <>
            <div className="row" key={'gq'+eachQ.value}>
              <div className="small-12 columns">
                {eachQ.value}. {eachQ.label}
              </div>
            </div>
            <div className="row top_space1" key={'gqq'+eachQ.value}>
              {getAnswer[eachQ.value-1].map(each =>
                <div className="small-12 medium-6 columns" key={'gc'+eachQ.value+each.value}>
                  <input type="radio" name={"q"+eachQ.value} value={each.value}
                  checked={this.state["q"+eachQ.value] == each.value && 'checked'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span>{each.label}</span>
                </div>
              )}
              <div className="small-12 columns">
                <div className="sep_line2">&nbsp;</div>
              </div>
            </div></>)}
            <div className="row" >
              <div className="small-12 columns">Comments:</div>
              <div className="small-12 columns">
                <input type="text" name="comments" value={this.state.comments}
                onChange={(e) => this.handleChange(e)} />
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <div className="sep_line2">&nbsp;</div>
              </div>
              <div className="small-12 columns copyright1">
                This survey uses items and item responses from the Client Satisfaction Questionnaire© (CSQ-8©, CSQ-3©, CSQ-4©, CSQ-18A/B©), by permission of the copyright holder. Copyright©2023. Clifford Attkisson, Ph.D. Use, transfer, copying, reproduction, merger, translation, modification, or enhancement (in any version, format, and/or media including electronic), in whole or in part, is forbidden without written permission by Dr. Attkisson. Contact: Info@CSQscales.com.
              </div>
            </div>
          </div>
        <div className="small-12 columns align_right">
          <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              { this.state.status === 1 ? 'Submit' : 'Next' }
          </Button>
        </div>
      </div>
      </form>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('patientstate STATE: ', state)
  //console.log("timepoint: "+this.props.match.params.timepoint)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CSQ8)
