export const questions = [
  {
    label: 'How would you rate the quality of the FITT-BD program?',
    value: 1,
  }, {
    label: 'Did you get the kind of service you wanted?',
    value: 2,
  }, {
    label: 'To what extent has the FITT-BD program met your needs?',
    value: 3,
  }, {
    label: 'If a friend were in need of similar help, would you recommend our program to him or her?',
    value: 4,
  }, {
    label: 'How satisfied are you with the amount of help you received?',
    value: 5,
  }, {
    label: 'Has the FITT-BD program helped you to deal more effectively with your problems?',
    value: 6,
  }, {
    label: 'In an overall, general sense, how satisfied are you with the FITT-BD program?',
    value: 7,
  }, {
    label: 'If you were to seek help again, would you come back to our program?',
    value: 8,
  }
]

export const answer_1 = [
  {
    label: 'Excellent',
    value: 4,
  }, {
    label: 'Good',
    value: 3,
  }, {
    label: 'Fair',
    value: 2,
  }, {
    label: 'Poor',
    value: 1,
  },
]

export const answer_2 = [
  {
    label: 'No, definitely not',
    value: 1,
  }, {
    label: 'No, not really',
    value: 2,
  }, {
    label: 'Yes, generally',
    value: 3,
  }, {
    label: 'Yes, definitely',
    value: 4,
  },
]

export const answer_3 = [
  {
    label: 'Almost all of my needs have been met',
    value: 4,
  }, {
    label: 'Most of my needs have been met',
    value: 3,
  }, {
    label: 'Only a few of my needs have been met',
    value: 2,
  }, {
    label: 'None of my needs have been met',
    value: 1,
  },
]

export const answer_4 = [
  {
    label: 'No, definitely not',
    value: 1,
  }, {
    label: 'No, I don’t think so',
    value: 2,
  }, {
    label: 'Yes, I think so',
    value: 3,
  }, {
    label: 'Yes, definitely',
    value: 4,
  },
]

export const answer_5 = [
  {
    label: 'Quite dissatisfied',
    value: 1,
  }, {
    label: 'Indifferent or mildly dissatisfied',
    value: 2,
  }, {
    label: 'Mostly satisfied',
    value: 3,
  }, {
    label: 'Very satisfied',
    value: 4,
  },
]

export const answer_6 = [
  {
    label: 'Yes, it helped a great deal',
    value: 4,
  }, {
    label: 'Yes, it helped somewhat',
    value: 3,
  }, {
    label: 'No, it really didn’t help',
    value: 2,
  }, {
    label: 'No, it seemed to make things worse',
    value: 1,
  },
]

export const answer_7 = [
  {
    label: 'Very satisfied',
    value: 4,
  }, {
    label: 'Mostly satisfied',
    value: 3,
  }, {
    label: 'Indifferent or mildly dissatisfied',
    value: 2,
  }, {
    label: 'Quite dissatisfied',
    value: 1,
  },
]

export const answer_8 = [
  {
    label: 'No, definitely not',
    value: 1,
  }, {
    label: 'No, I don’t think so',
    value: 2,
  }, {
    label: 'Yes, I think so',
    value: 3,
  }, {
    label: 'Yes, definitely',
    value: 4,
  },
]
