import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { tobacco_scale, audit_c1_scale, audit_c2_scale,
audit_c3_scale, mini_borderline, mini_eligible, scid_yesno } from '../variables/assessments/scid.js'
import { scid_ynne, mini_not_completed_reason, scid_v1_q_a, scid_v1_q_b, scid_b7_severity,
scid_v1_q_b7, scid_v1_q_c, scid_v1_q_c11, scid_v1_q_c12, scid_ynmb, scid_checked }
from '../variables/assessments/scid_v1.js'

import PatientHead3 from "../patient/patient_head3"
import ReactHtmlParser from 'react-html-parser'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import Button from '@material-ui/core/Button'
import WarningIcon from '@material-ui/icons/Warning'

class ScidV1Result extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          message: '',
          dataItems: [],
          date_visit: '',
      }
  }

  componentDidMount() {
    this.getData()
    window.scrollTo(0, 0)
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient_assessment/view_scid_v1_result',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      if(result['data'] != 0) {
        this.setState({
          dataItems: result['data'][0],
          date_visit: this.convertDateFormat(result['data'][0].date_visit),
        })
      }
    })
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => { return obj.value === searchValue })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  convertDateFormat = (date) => {
    var buff = date.split('-')
    return buff[1]+'/'+buff[2]+'/'+buff[0]
  }

  render() {
    return (
      <div className="admin_patient_detail box_border4 mini_result">
        <div className="row">
          <div className="small-12 columns headerPage1">SCID Result</div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border5">
              <PatientHead3 />
              <div className="row">
                <div className="small-12 columns box_inner2 top_space1">
                  <div className="small-12 columns">

                  <div className="small-12 columns box5 top_space1">
                    <div>
                      <div className="small-12 columns">
                      Rater: {this.state.dataItems.cname}
                      </div>
                      <div className="small-12 columns">
                      Date visit: {this.state.date_visit}
                      </div>
                    </div>
                  </div>

                  <div className="small-12 columns box5 top_space1">
                    <div>
                      <div className="small-12 columns">
                      {scid_v1_q_a.map(each =>
                      <div className="row no_margin_bottom">
                        <div className="small-10 columns">
                        A{each.value}. {each.label}
                        {(each.value == 9 && this.state.dataItems.a9_other != '') &&
                          ' ['+this.state.dataItems.a9_other+']'}
                        {(each.value == 11 && this.state.dataItems.a11_other != '') &&
                          ' ['+this.state.dataItems.a11_other+']'}
                        </div>
                        <div className="small-2 columns">{this.findLabelByValue(scid_ynne, this.state.dataItems['a'+each.value])}</div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                      </div>
                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom text_align_right">
                            <a href={"/admin/assessments/edit_scid1_v1/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                                Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="small-12 columns box5 top_space1">
                    <div>
                      <div className="small-12 columns">
                      {scid_v1_q_b.map(each =>
                      <div className="row no_margin_bottom">
                        <div className="small-10 columns">B{each.value}. {each.label}</div>
                        <div className="small-2 columns">{this.findLabelByValue(scid_ynne, this.state.dataItems['b'+each.value])}</div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                      <div className="row no_margin_bottom">
                        <div className="small-12 columns">B7. Check the category of substance use disorder:</div>
                        {scid_v1_q_b7.map(each => <>
                          <div className="small-9 columns">- {each.label}</div>
                          <div className="small-1 columns">{this.findLabelByValue(scid_checked, this.state.dataItems['b7'+each.value])}</div>
                          <div className="small-2 columns">
                          {this.state.dataItems['b7'+each.value+"_severity"] > 0 &&
                          "/ " + this.findLabelByValue(scid_b7_severity, this.state.dataItems['b7'+each.value+"_severity"])}
                          </div>
                        </>)}
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>
                      </div>
                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom text_align_right">
                            <a href={"/admin/assessments/edit_scid2_v1/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                                Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="small-12 columns box5 top_space1">
                    <div>
                      <div className="small-12 columns">
                      {scid_v1_q_c.map(each =>
                      <div className="row no_margin_bottom">
                        <div className="small-10 columns">C{each.value}. {each.label}</div>
                        <div className="small-2 columns">{this.findLabelByValue(scid_ynne, this.state.dataItems['c'+each.value])}</div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                      <div className="row no_margin_bottom">
                        <div className="small-12 columns">C11. Check the type of ADHD:</div>
                        {scid_v1_q_c11.map(each => <>
                          <div className="small-10 columns">- {each.label}</div>
                          <div className="small-2 columns">{this.findLabelByValue(scid_checked, this.state.dataItems['c11'+each.value])}</div>
                        </>)}
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>
                      {scid_v1_q_c12.map(each =>
                      <div className="row no_margin_bottom">
                        <div className="small-10 columns">C{each.value}. {each.label}</div>
                        <div className="small-2 columns">{this.findLabelByValue(scid_ynne, this.state.dataItems['c'+each.value])}</div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                      </div>
                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom text_align_right">
                            <a href={"/admin/assessments/edit_scid3_v1/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                                Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="small-12 columns box5 top_space1">
                  <div className="small-12 columns">
                    <div className="row no_margin_bottom">
                      <div className="small-9 columns">Tobacco 30 day use: Has the patient used any tobacco product in the past 30 days?</div>
                      <div className="small-3 columns">{this.findLabelByValue(tobacco_scale, this.state.dataItems['tobacco'])}</div>
                    </div>
                    <div className="small-12 columns sep_line1">&nbsp;</div>
                  </div>

                  <div>
                  <div className="small-12 columns">
                    <div className="row no_margin_bottom">
                      <div className="small-9 columns">AUDIT-C: 1. How often do you have a drink containing alcohol?</div>
                      <div className="small-3 columns">{this.findLabelByValue(audit_c1_scale, this.state.dataItems['audit_c_1'])}</div>
                    </div>
                    <div className="small-12 columns sep_line1">&nbsp;</div>
                  </div>

                  <div className="small-12 columns">
                    <div className="row no_margin_bottom">
                      <div className="small-9 columns">AUDIT-C: 2. How many standard drinks containing alcohol do you have on a typical day?</div>
                      <div className="small-3 columns">{this.findLabelByValue(audit_c2_scale, this.state.dataItems['audit_c_2'])}</div>
                    </div>
                    <div className="small-12 columns sep_line1">&nbsp;</div>
                  </div>

                  <div className="small-12 columns">
                    <div className="row no_margin_bottom">
                      <div className="small-9 columns">AUDIT-C: 3. How often do you have 6 drinks or more on one occasion?</div>
                      <div className="small-3 columns">{this.findLabelByValue(audit_c3_scale, this.state.dataItems['audit_c_3'])}</div>
                    </div>
                  </div>

                  </div>
                  <div className="small-12 columns sep_line1">&nbsp;</div>
                  <div>
                    <div className="small-12 columns">
                      <div className="row no_margin_bottom text_align_right">
                        <a href={"/admin/assessments/edit_scid4_v1/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                            Edit
                        </a>
                      </div>
                    </div>
                  </div>
                  </div>

                  <div className="small-12 columns box5 top_space1">
                  <div>{mini_borderline.map(each =>
                    <div className="small-12 columns">
                      <div className="row no_margin_bottom">
                        <div className="small-9 columns">{each.value}. {each.label}</div>
                        <div className="small-3 columns">
                        {this.findLabelByValue(scid_yesno, this.state.dataItems['bdl'+each.value])}
                        </div>
                      </div>
                      <div className="small-12 columns sep_line1">&nbsp;</div>
                    </div>)}
                  </div>
                  <div>
                    <div className="small-12 columns">
                      <div className="row no_margin_bottom text_align_right">
                        <a href={"/admin/assessments/edit_scid5_v1/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                            Edit
                        </a>
                      </div>
                    </div>
                  </div>
                  </div>

                  <div className="small-12 columns box5 top_space1">
                    <div>
                      <div className="small-12 columns">
                        <div className="small-12 columns"><span className="bold">Result: </span>
                          {this.findLabelByValue(mini_eligible, this.state.dataItems.bd_result)}
                        </div>
                        <div className="small-12 columns"><span className="bold">Note: </span><br />
                          {ReactHtmlParser(this.state.dataItems.note)}
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>
                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom text_align_right">
                            <a href={"/admin/assessments/edit_scid6_v1/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                                Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

/*<div className="small-12 columns box5 top_space1">
<div>{mini_q_c.map(each =>
  <div className="small-12 columns">
    <div className="row no_margin_bottom">
      <div className="small-9 columns">C{each.value}. {each.label}</div>
      <div className="small-3 columns">
      {this.findLabelByValue(mini_scale1, this.state.dataItems['c'+each.value])}
      </div>
    </div>
    <div className="small-12 columns sep_line1">&nbsp;</div>
  </div>)}
</div>
<div>
  <div className="small-12 columns">
    <div className="row no_margin_bottom text_align_right">
      <a href={"/admin/assessments/edit_mini2/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
          Edit
      </a>
    </div>
  </div>
</div>
</div>*/

const mapStateToProps = (state) => {
  //console.log('patientstate STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ScidV1Result)
