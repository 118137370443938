import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/dashboard.css'
import { CgFileDocument } from 'react-icons/cg'
import FiberNewIcon from '@material-ui/icons/FiberNew'
import axios from 'axios'
import { connectNode } from '../../../utils/global'

class Worksheets extends Component {
  constructor() {
    super()
    this.state = {
      last_assigned_days: 0,
      cnt_worksheet: 0,
    }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/worksheets/get_last_worksheet_assigned',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data'].length > 0) {
        this.setState({
          last_assigned_days: result['data'][0].cnt_days,
          cnt_worksheet: 1,
        })
      }
    })
  }

  render() {
    return (
      <div className="box_border box_space_top1">
        <div className="row">
            <div className="small-12 columns head3 pat_contract">
                <a href={"member/worksheets/worksheet_list"} >
                  <div className="txt_link">
                    <div className=""><CgFileDocument /></div>
                    <div className="top_space5">Worksheets / Self-Guided Program</div>
                    {/*<div className="top_space5">{this.state.cnt_worksheet == 0 && 'No '} Worksheet{this.state.cnt_worksheet > 0 && 's'}</div>
                    {this.state.last_assigned_days > 0 && this.state.last_assigned_days <= 30 && <FiberNewIcon className="red_new1" />}*/}
                  </div>
                </a>
            </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  //console.log('PatientList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Worksheets)
