import React from "react";
import { Line } from "./line";
import TextField from "@material-ui/core/TextField";

export const TextFieldLine = (props) => {
  //console.log(props)
  return (
    <Line label={props.label}>
      <div className="small-12 medium-9 columns">
        <TextField
          style={{backgroundColor: props.multiline ? 'white' : undefined}}
          id={props.id}
          label=""
          onChange={props.onChange}
          variant="outlined"
          type={props.type ?? "text"}
          defaultValue={props.defaultValue}
          value={props.value}
          multiline={props.multiline}
          InputProps={{
            inputProps: { name: props.name, maxLength: props.maxLength },
          }}
        />
      </div>
    </Line>
  );
};
