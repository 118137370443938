import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'

import '../../../../styles/assets/css/pages/graph.css'
import { FiSettings } from 'react-icons/fi'
import SettingsIcon from '@material-ui/icons/Settings'
import Pagination from '../../../functions/pagination'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Spinner from 'react-spinner-material'
import { VictoryChart, VictoryLine, VictoryTheme, VictoryAxis } from 'victory'
import { db_type, db_data_show, db_scale_day, db_scale_week } from '../../../patients/variables/dashboard.js'
import { daily_tracker_sleep, daily_tracker_sleep2, weekly_tracker_sleep,
  daily_tracker_medication, weekly_tracker_medication, daily_tracker_exercise2,
  daily_tracker_exercise, weekly_tracker_exercise } from '../variables/assessments_scale.js'
import Graph2 from "../../../patients/dashboard/graph2"
import StepTrackingGraph from "./step_tracking_graph"

class AssmList extends Component {
  constructor() {
    super(  );
    this.state = {
        dataItems: [],
        pageOfItems: [],
        loading: false,
        graph_config_status: 1, // 1:hide, 2:show
        graph_type: 1, // 1:day, 2:week
        graph_data_show: 1, // 1: mood-depressed, 2: mood-manic, 3: sleep, 4: medication, 5: exercise, 6: anxiety
        data_baseline: {},
        data_discharge: {},
        start_baseline: '',
        end_baseline: '',
        start_discharge: '',
        end_discharge: '',
        data_weekly: [],
        pageOfItemsWeek: [],
        data_monthly: [],
        pageOfItemsMonth: [],
        data_trimonthly: [],
        pageOfItemsTriMonth: [],
        data_sixmonthly: [],
        pageOfItemsSixMonth: [],
        data_yearly: [],
        pageOfItemsYear: [],
        tracker_day: [],
        pageOfItemsTrackerDay: [],
        tracker_week: [],
        pageOfItemsTrackerWeek: [],
        self_stigma_assm: [],
        step_tracking: 0,
        discharge_id: 0,
    }
    this.onChangePage = this.onChangePage.bind(this)
    this.onChangePageWeek = this.onChangePageWeek.bind(this)
    this.onChangePageMonth = this.onChangePageMonth.bind(this)
    this.onChangePageTriMonth = this.onChangePageTriMonth.bind(this)
    this.onChangePageSixMonth = this.onChangePageSixMonth.bind(this)
    this.onChangePageYear = this.onChangePageYear.bind(this)
    this.onChangePageTrackerDay = this.onChangePageTrackerDay.bind(this)
    this.onChangePageTrackerWeek = this.onChangePageTrackerWeek.bind(this)
  }

  onChangePageWeek(pageOfItems) {
    this.setState({ pageOfItemsWeek: pageOfItems })
  }

  onChangePageMonth(pageOfItems) {
    this.setState({ pageOfItemsMonth: pageOfItems })
  }

  onChangePageTriMonth(pageOfItems) {
    this.setState({ pageOfItemsTriMonth: pageOfItems })
  }

  onChangePageSixMonth(pageOfItems) {
    this.setState({ pageOfItemsSixMonth: pageOfItems })
  }

  onChangePageYear(pageOfItems) {
    this.setState({ pageOfItemsYear: pageOfItems })
  }

  onChangePageTrackerDay(pageOfItems) {
    this.setState({ pageOfItemsTrackerDay: pageOfItems })
  }

  onChangePageTrackerWeek(pageOfItems) {
    this.setState({ pageOfItemsTrackerWeek: pageOfItems })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    var cal_baseline = []
    var cal_discharge = []
    var cal_assm = {}
    var find_baseline = -1
    var find_discharge = -1

    axios({
      method: 'post',
      url: connectNode + 'admin/patient_assessment/get_patient_assm_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid,
        typeD: 1,
        typeW: 2,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      var getData = []
      if(result['data'][0].status == "fulfilled") {
        getData = result['data'][0].value
        //console.log(getData[0])
        if(getData.length > 0) {
          find_baseline = getData.findIndex(item => item.timepoint == -1)
          cal_baseline = this.calculateBaseline(getData[find_baseline])
          //console.log(find_baseline)
          cal_assm = this.calculateAssm(getData)

          find_discharge = getData.findIndex(item => item.timepoint == -2)
          //console.log(find_discharge)

          var discharge_start = ''
          var discharge_end = ''
          if(find_discharge >= 0) {
            cal_discharge = this.calculateDischarge(getData[find_discharge])
            discharge_start = getData[find_discharge].conv_start_date_time
            discharge_end = getData[find_discharge].conv_end_date_time
          }

          this.setState({
            data_baseline: cal_baseline,
            data_discharge: cal_discharge,
            data_weekly: cal_assm.weekly,
            cnt_phq4: cal_assm.cnt_phq4,
            data_monthly: cal_assm.monthly,
            data_trimonthly:cal_assm.trimonthly,
            data_sixmonthly:cal_assm.sixmonthly,
            data_yearly: cal_assm.yearly,
            start_baseline: getData[find_baseline].start_baseline,
            end_baseline: getData[find_baseline].end_baseline,
            start_discharge: discharge_start,
            end_discharge: discharge_end,
            discharge_id: getData[0].discharge_id,
            loading: true,
            //end_date_time: getData
          })
        }
      }

      if(result['data'][1].status == "fulfilled") {
        getData = result['data'][1].value
        if(getData.length > 0) {
          this.setState({
            tracker_day: getData,
          })
        }
      }

      if(result['data'][2].status == "fulfilled") {
        getData = result['data'][2].value
        if(getData.length > 0) {
          this.setState({
            tracker_week: getData,
          })
        }
      }

      if(result['data'][3].status == "fulfilled") {
        this.setState({
          self_stigma_assm: result['data'][3].value,
        })
      }
      //console.log(result['data'][4])

      if(result['data'][4].status == "fulfilled") {
        this.setState({
          step_tracking: result['data'][4].value,
        })
      }
    })

  }

  calculateAssm = (data) => {
    var cal = {
      cnt_weekly: 0,
      weekly: [],
      cnt_phq4: 0,
      cnt_monthly: 0,
      monthly: [],
      cnt_trimonthly: 0,
      trimonthly: [],
      cnt_sixmonth: 0,
      sixmonthly: [],
      cnt_yearly: 0,
      yearly: [],
    }
    data.map(each => {
      if(each['timepoint'] > 0 || (each['timepoint'] <= -9 && each['timepoint_type'] == 'm')
        || (each['timepoint'] <= -9 && each['timepoint_type'] == 'tm')) {
        var buff_phq4 = '-'
        var buff_who5 = '-'
        var cnt_phq4 = 0
        var chk_covid = 0

        if(each['timepoint_type'] == 'w') {
          cal.cnt_weekly++
          if(each['phq4_q1'] != null) {
            cal.cnt_phq4++
            buff_phq4 = each['phq4_q1'] + each['phq4_q2'] + each['phq4_q3'] + each['phq4_q4']
          }
          if(each['who5_q1'] != null) {
            buff_who5 = each['who5_q1'] + each['who5_q2'] + each['who5_q3'] + each['who5_q4'] + each['who5_q5']
          }
          cal.weekly.push({
            cnt: cal.cnt_weekly,
            timepoint: each['timepoint'],
            datetime: each['conv_start_date_time'],
            phq4: buff_phq4,
            who5: buff_who5,
          })
        } else if(each['timepoint_type'] == 'm') {
          cal.cnt_monthly++
          cal.monthly.push({
            cnt: cal.cnt_monthly,
            timepoint: each['timepoint'],
            datetime: each['conv_start_date_time'],
            phq9: this.calculatePHQ9(each),
            asrm: this.calculateASRM(each),
            gad7: this.calculateGAD7(each),
            psqi: this.calculatePSQI(each),
            who5: this.calculateWHO5(each),
            mtf: each.mtf_id,
            end_date_time: each.conv_end_date_time,
          })
        } else if(each['timepoint_type'] == 'tm') {
          cal.cnt_trimonthly++
          if(each.covid_q1 > 0) {
            chk_covid = 1
          }
          cal.trimonthly.push({
            cnt: cal.cnt_trimonthly,
            timepoint: each['timepoint'],
            datetime: each['conv_start_date_time'],
            pw18: this.calculatePW18(each),
            covid: chk_covid,
            //csq8: this.calculateCSQ8(each),
          })
        } else if(each['timepoint_type'] == 'sm') {
          cal.cnt_sixmonth++
          cal.sixmonthly.push({
            cnt: cal.cnt_sixmonth,
            timepoint: each['timepoint'],
            datetime: each['conv_start_date_time'],
            ders18: this.calculateDERS18(each),
            csq8: this.calculateCSQ8(each),
          })
        } else if(each['timepoint_type'] == 'y') {
          cal.cnt_yearly++
          cal.yearly.push({
            cnt: cal.cnt_yearly,
            timepoint: each['timepoint'],
            datetime: each['conv_start_date_time'],
            ders18: this.calculateDERS18(each),
          })
        }
      }
    })
    return cal
  }

  calculateBaseline = (data) => {
    //console.log(data)
    var cal = {
      phq9: this.calculatePHQ9(data),
      asrm: this.calculateASRM(data),
      gad7: this.calculateGAD7(data),
      cobra: this.calculateCOBRA(data),
      pw18: this.calculatePW18(data),
      ders18: this.calculateDERS18(data),
      psqi: this.calculatePSQI(data),
    }
    return cal
  }

  calculateDischarge = (data) => {
    //console.log(data)
    var cal = {
      phq9: this.calculatePHQ9(data),
      asrm: this.calculateASRM(data),
      gad7: this.calculateGAD7(data),
      cobra: this.calculateCOBRA(data),
      pw18: this.calculatePW18(data),
      ders18: this.calculateDERS18(data),
      psqi: this.calculatePSQI(data),
      who5: this.calculateWHO5(data),
      csq8: this.calculateCSQ8(data),
    }
    return cal
  }

  calculateWHO5 = (data) => {
    var sum = '-'
    if(data['who5_q1'] != null) {
      sum = data['who5_q1'] + data['who5_q2'] + data['who5_q3'] + data['who5_q4'] + data['who5_q5']
    }
    return sum
  }

  calculatePHQ9 = (data) => {
    var sum = '-'
    if(data['phq9_q1'] != null) {
      sum = data['phq9_q1'] + data['phq9_q2'] + data['phq9_q3'] + data['phq9_q4'] + data['phq9_q5'] + data['phq9_q6'] + data['phq9_q7'] + data['phq9_q8'] + data['phq9_q9']
    }
    return sum
  }

  calculateASRM = (data) => {
    let sum = '-'
    if(data['asrm_q1'] != null) {
      sum = data['asrm_q1'] + data['asrm_q2'] + data['asrm_q3'] + data['asrm_q4'] + data['asrm_q5']
    }
    return sum
  }

  calculateGAD7 = (data) => {
    let sum = '-'
    if(data['gad7_q1'] != null) {
      sum = data['gad7_q1'] + data['gad7_q2'] + data['gad7_q3'] + data['gad7_q4'] + data['gad7_q5'] + data['gad7_q6'] + data['gad7_q7']
    }
    return sum
  }

  calculateCOBRA = (data) => {
    let sum = '-'
    if(data['cobra_q1'] != null) {
      sum = data['cobra_q1'] + data['cobra_q2'] + data['cobra_q3'] + data['cobra_q4'] + data['cobra_q5'] + data['cobra_q6'] + data['cobra_q7'] + data['cobra_q8'] + data['cobra_q9'] + data['cobra_q10'] + data['cobra_q11'] + data['cobra_q12'] + data['cobra_q13'] + data['cobra_q14'] + data['cobra_q15'] + data['cobra_q16']
    }
    return sum
  }

  calculatePW18 = (gdata) => {
    let sum = '-'
    if(gdata['pw18_q4'] != null) {
      sum = gdata['pw18_q4'] + gdata['pw18_q5'] + gdata['pw18_q6'] + gdata['pw18_q7'] + gdata['pw18_q10'] + gdata['pw18_q14'] + gdata['pw18_q15'] + gdata['pw18_q16']
      const list_q = [1, 2, 3, 8, 9, 11, 12, 13, 17, 18]
      list_q.map(i => {
        sum += (8 - gdata['pw18_q'+i])
      })
    }
    return sum
  }

  calculateCSQ8 = (gdata) => {
    let sum = '-'
    if(gdata['csq8_q1'] != null) {
      sum = gdata['csq8_q1'] + gdata['csq8_q2'] + gdata['csq8_q3'] + gdata['csq8_q4'] + gdata['csq8_q5'] + gdata['csq8_q6'] + gdata['csq8_q7'] + gdata['csq8_q8']
    }
    return sum
  }

  calculateDERS18 = (gdata) => {
    let sum = 0
    if(gdata['ders18_q2'] != null) {
      // Awareness: convert score //
      const list_q = [1, 4, 6]
      list_q.map(i => {
        sum += (6 - gdata['ders18_q'+i])
      })

      // Clarity //
      sum += gdata['ders18_q2'] + gdata['ders18_q3'] + gdata['ders18_q5']

      // Goals //
      sum += gdata['ders18_q8'] + gdata['ders18_q12'] + gdata['ders18_q15']

      // Impulse //
      sum += gdata['ders18_q9'] + gdata['ders18_q16'] + gdata['ders18_q18']

      // Nonacceptance //
      sum += gdata['ders18_q7'] + gdata['ders18_q13'] + gdata['ders18_q14']

      // Strategies //
      sum += gdata['ders18_q10'] + gdata['ders18_q11'] + gdata['ders18_q17']
    } else {
      sum = '-'
    }
    return sum
  }

  calculatePSQI = (gdata) => {
    if(gdata['psqi_q1']) {
      // Component 1: Q6 //
      let comp1 = gdata['psqi_q6']

      // Component 2: Q2, Q5a //
      let comp2 = 0
      let g_q2 = gdata['psqi_q2']
      if(g_q2 >= 16 && g_q2 <= 30) {
        comp2 = 1
      } else if(g_q2 >= 31 && g_q2 <= 60) {
        comp2 = 2
      }else if(g_q2 > 60) {
        comp2 = 3
      }
      comp2 += gdata['psqi_q51']

      // Component 3: Q4 //
      let comp3 = 0
      let g_q4 = gdata['psqi_q4']
      if(g_q4 >= 6 && g_q4 <= 7) {
        comp3 = 1
      } else if(g_q4 >= 5 && g_q4 <= 6) {
        comp3 = 2
      }else if(g_q4 < 5) {
        comp3 = 3
      }

      // Component 4: Q1, Q3, Q4 //
      let timeStart = new Date("01/01/2021 " + gdata['psqi_q1'])
      let timeEnd
      if(gdata['psqi_q1'].substr(0, 2) > gdata['psqi_q3'].substr(0, 2)) {
        timeEnd = new Date("01/02/2021 " + gdata['psqi_q3'])
      } else {
        timeEnd = new Date("01/01/2021 " + gdata['psqi_q3'])
      }
      let spent_bed = (timeEnd - timeStart) / (60 * 60 * 1000)
      let habit = (g_q4 / spent_bed) * 100
      //console.log(g_q4 + ' / ' + spent_bed + ' : ' + habit)
      let comp4 = 0
      if(habit >= 75 && habit < 85) {
        comp4 = 1
      } else if(habit >= 65 && habit < 75) {
        comp4 = 2
      } else if(habit < 65) {
        comp4 = 3
      }

      // Component 5: Q5b-5j //
      let comp5 = 0
      let sum5 = 0
      for(let i = 2; i<=10; i++) {
        sum5 += gdata['psqi_q5'+i]
      }
      if(sum5 >= 1 && sum5 <= 9) {
        comp5 = 1
      } else if(sum5 >= 10 && sum5 <= 18) {
        comp5 = 2
      } else if(sum5 >= 19 && sum5 <= 27) {
        comp5 = 3
      }

      // Component 6: Q7 //
      let comp6 = gdata['psqi_q7']

      // Component 7: Q8, Q9 //
      let sum7 = gdata['psqi_q8'] + gdata['psqi_q9']
      let comp7 = 0
      if(sum7 >= 1 && sum7 <= 2) {
        comp7 = 1
      } else if(sum7 >= 3 && sum7 <= 4) {
        comp7 = 2
      } else if(sum7 >= 5 && sum7 <= 6) {
        comp7 = 3
      }

      // Global Score: sum all //
      let sum_score = 0
      if(gdata['psqi_q1'] != null) {
        sum_score = comp1 + comp2 + comp3 + comp4 + comp5 + comp6 + comp7
      } else {
        sum_score = '-'
      }
      return sum_score
    }
  }

  genMedTracker = (num) => {
    var str = ''

    if(num == 0.5) {
      str = daily_tracker_medication[0] + '/' + daily_tracker_medication[1]
    } else if(num == 1.5) {
      str = daily_tracker_medication[1] + '/' + daily_tracker_medication[2]
    } else if(num == 2.5) {
      str = daily_tracker_medication[2] + '/' + daily_tracker_medication[3]
    } else if(num == 3.5) {
      str = daily_tracker_medication[3] + '/' + daily_tracker_medication[4]
    } else if(num == 4.5) {
      str = daily_tracker_medication[4] + '/' + daily_tracker_medication[5]
    } else {
      str = daily_tracker_medication[num]
    }
    return str
  }

  render() {
    //console.log(this.state)
    return (
      <div className="patient_dashboard">
      {!this.state.loading &&
      <div className="row">
        <div className="small-12 columns loading2">
          <Spinner color={"#bbbbbb"} radius={50}/>
        </div>
      </div>}
      {this.state.loading &&
        <div className="row">

          <div className="small-12 columns">
            <div className="title6">
              Baseline Assessments:
              <span className="no_bold"> Start {this.state.start_baseline} | Finish {this.state.end_baseline}</span>
            </div>
          </div>
          <div className="small-12 columns">
            <table className="table_data1">
              <thead className="table_head">
                <tr>
                  <th>PHQ-9</th>
                  <th>ASRM</th>
                  <th>GAD-7</th>
                  <th>COBRA</th>
                  <th>PWS</th>
                  <th>DERS</th>
                  <th>PSQI</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                  {this.state.data_baseline.phq9 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/phq9/m/-1"}
                    target="_blank">{this.state.data_baseline.phq9}</Link>}
                  {this.state.data_baseline.phq9 == '-' && '-'}
                  </td>
                  <td>
                  {this.state.data_baseline.asrm != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/asrm/m/-1"}
                    target="_blank">{this.state.data_baseline.asrm}</Link>}
                  {this.state.data_baseline.asrm == '-' && '-'}</td>
                  <td>
                  {this.state.data_baseline.gad7 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/gad7/m/-1"}
                    target="_blank">{this.state.data_baseline.gad7}</Link>}
                  {this.state.data_baseline.gad7 == '-' && '-'}
                  </td>
                  <td>
                  {this.state.data_baseline.cobra != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/cobra/m/-1"}
                    target="_blank">{this.state.data_baseline.cobra}</Link>}
                  {this.state.data_baseline.cobra == '-' && '-'}
                  </td>
                  <td>
                  {this.state.data_baseline.pw18 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/pw18/m/-1"}
                    target="_blank">{this.state.data_baseline.pw18}</Link>}
                  {this.state.data_baseline.pw18 == '-' && '-'}
                  </td>
                  <td>
                  {this.state.data_baseline.ders18 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/ders18/m/-1"}
                    target="_blank">{this.state.data_baseline.ders18}</Link>}
                  {this.state.data_baseline.ders18 == '-' && '-'}
                  </td>
                  <td>
                  {this.state.data_baseline.psqi != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/psqi/m/-1"}
                    target="_blank">{this.state.data_baseline.psqi}</Link>}
                  {this.state.data_baseline.psqi == '-' && '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="small-12 columns">
            <table className="table_data1">
              <tr>
                <th className="title6 small-5 columns margin_top_1 align_right">Resource use score (Last 30 days):</th>
                <td className="small-7 columns align_left">{this.state.step_tracking}</td>
              </tr>
            </table>
          </div>
          <div className="small-12 columns">
            <div className="title6">
              In-Clinic Resource Tracking
            </div>
          </div>
          <StepTrackingGraph pid={this.props.match.params.pid} />{/**/}

          <div className="small-12 columns">
            <div className="title6">
              Daily Tracker
            </div>
          </div>
          <div className="small-12 columns">
            <table className="table_data1 no_bottom_1">
              <thead className="table_head">
                <tr>
                  <th>Date</th>
                  <th>Mood / Depressed</th>
                  <th>Mood / Manic</th>
                  <th>Sleep</th>
                  <th>Medication</th>
                  <th>Exercise</th>
                  <th>Anxiety</th>
                </tr>
              </thead>
              <tbody>
              {this.state.tracker_day && this.state.pageOfItemsTrackerDay.map(each =>
                <tr key={'trd'+each.row_number}>
                  <td>{each.conv_date_time}</td>
                  <td>{each.mood_depressed}</td>
                  <td>{each.mood_manic}</td>
                  <td>{daily_tracker_sleep2[(each.sleep*2)-1]}</td>
                  <td>{this.genMedTracker(each.medication)}</td>
                  <td>{daily_tracker_exercise2[(each.exercise*2)-1]}</td>
                  <td>{each.anxiety}</td>
                </tr>
              )}
              {this.state.tracker_day &&
                <tr className="pagination_block">
                  <td colSpan="8">
                    <Pagination items={this.state.tracker_day} pageSize={10} onChangePage={this.onChangePageTrackerDay} />
                  </td>
                </tr>}
              </tbody>
            </table>
            <p className="gray_color">
              <b>Mood / Depressed</b> &nbsp; 1: Not at all Depressed, 5: Extremely Depressed<br />
              <b>Mood / Manic</b> &nbsp; 1: Not at all Manic, 5: Extremely Manic<br />
              <b>Anxiety</b> &nbsp; 1: Not at all Anxious, 5: Extremely Anxious
            </p>
          </div>
          <div className="small-12 columns">
            <div className="title6">
              Weekly Tracker
            </div>
          </div>
          <div className="small-12 columns">
            <table className="table_data1 no_bottom_1">
              <thead className="table_head">
                <tr>
                  <th>Date</th>
                  <th>Mood / Depressed</th>
                  <th>Mood / Manic</th>
                  <th>Sleep</th>
                  <th>Medication</th>
                  <th>Exercise</th>
                  <th>Anxiety</th>
                </tr>
              </thead>
              <tbody>
              {this.state.tracker_week && this.state.pageOfItemsTrackerWeek.map(each =>
                <tr key={'trw'+each.row_number}>
                  <td>{each.conv_date_time}</td>
                  <td>{each.mood_depressed}</td>
                  <td>{each.mood_manic}</td>
                  <td>{weekly_tracker_sleep[(each.sleep*2)-1]}</td>
                  <td>{weekly_tracker_medication[each.medication]}</td>
                  <td>{weekly_tracker_exercise[(each.exercise*2)-1]}</td>
                  <td>{each.anxiety}</td>
                </tr>
              )}
              {this.state.tracker_week &&
                <tr className="pagination_block">
                  <td colSpan="8">
                    <Pagination items={this.state.tracker_week} pageSize={10} onChangePage={this.onChangePageTrackerWeek} />
                  </td>
                </tr>}
              </tbody>
            </table>
            <p className="gray_color">
              <b>Mood / Depressed</b> &nbsp; 1: Not at all Depressed, 5: Extremely Depressed<br />
              <b>Mood / Manic</b> &nbsp; 1: Not at all Manic, 5: Extremely Manic<br />
              <b>Anxiety</b> &nbsp; 1: Not at all Anxious, 5: Extremely Anxious
            </p>
          </div>

          {/* Graph */}
          <Graph2 pid={this.props.match.params.pid} />

          <div className="small-12 columns">
            <div className="title6">
              Weekly Assessments
            </div>
          </div>
          <div className="small-12 columns">
            <table className="table_data1">
              <thead className="table_head">
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Week</th>
                  {this.state.cnt_phq4 > 0 &&
                  <th>PHQ-4</th>}
                  <th>WHO-5</th>
                </tr>
              </thead>
              <tbody>
              {this.state.data_weekly && this.state.pageOfItemsWeek.map((item) =>
                <tr key={'w'+item.cnt}>
                  <td>{item.cnt}</td>
                  <td>{item.datetime}</td>
                  <td>{item.timepoint}</td>
                  {this.state.cnt_phq4 > 0 &&
                  <td>
                  {item.phq4 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/phq4/w/"+item.timepoint}
                    target="_blank">{item.phq4}</Link>}
                  {item.phq4 == '-' && '-'}
                  </td>}
                    <td>
                    {item.who5 != '-' &&
                      <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/who5/w/"+item.timepoint}
                      target="_blank">{item.who5}</Link>}
                    {item.who5 == '-' && '-'}
                    </td>
                </tr>)}
              {this.state.data_weekly &&
                <tr className="pagination_block">
                  <td colSpan="5">
                    <Pagination items={this.state.data_weekly} pageSize={10} onChangePage={this.onChangePageWeek} />
                  </td>
                </tr>}
              </tbody>
            </table>
          </div>

          <div className="small-12 columns">
            <div className="title6">
              Monthly Assessments
            </div>
          </div>
          <div className="small-12 columns">
            <table className="table_data1">
              <thead className="table_head">
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Month</th>
                  <th>PHQ-9</th>
                  <th>ASRM</th>
                  <th>GAD-7</th>
                  <th>PSQI</th>
                  <th>WHO-5</th>
                  <th>MTF</th>
                </tr>
              </thead>
              <tbody>
              {this.state.data_monthly && this.state.data_monthly.map((item) =>
                <tr key={'m'+item.cnt}>
                  <td>{item.cnt}</td>
                  <td>{item.datetime}</td>
                  <td>
                    {item.timepoint > 0 && item.timepoint}
                    {item.timepoint <= -9 && "Unscheduled"}
                  </td>
                  <td>
                  {item.phq9 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/phq9/m/"+item.timepoint}
                    target="_blank">{item.phq9}</Link>}
                  {item.phq9 == '-' && '-'}
                  </td>
                  <td>
                  {item.asrm != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/asrm/m/"+item.timepoint}
                    target="_blank">{item.asrm}</Link>}
                  {item.asrm == '-' && '-'}
                  </td>
                  <td>
                  {item.gad7 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/gad7/m/"+item.timepoint}
                    target="_blank">{item.gad7}</Link>}
                  {item.gad7 == '-' && '-'}
                  </td>
                  <td>
                  {item.psqi != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/psqi/m/"+item.timepoint}
                    target="_blank">{item.psqi}</Link>}
                  {item.psqi == undefined && '-'}
                  </td>
                  <td>
                  {item.who5 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/who5/m/"+item.timepoint}
                    target="_blank">{item.who5}</Link>}
                  {(item.who5 == undefined || item.who5 == '-') && '-'}
                  </td>
                  <td>
                  {item.end_date_time &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/mtf/m/"+item.timepoint}
                    target="_blank">MTF</Link>}
                  {item.end_date_time == null && '-'}
                  </td>

                </tr>)}
              {this.state.data_monthly &&
                <tr className="pagination_block">
                  <td colSpan="8">
                    <Pagination items={this.state.data_monthly} pageSize={10} onChangePage={this.onChangePageMonth} />
                  </td>
                </tr>}
              </tbody>
            </table>
          </div>

          <div className="small-12 columns">
            <div className="title6">
              Trimonthly Assessments
            </div>
          </div>
          <div className="small-12 columns">
            <table className="table_data1">
              <thead className="table_head">
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Trimonth</th>
                  <th>PWS</th>
                  <th>COVID</th>
                </tr>
              </thead>
              <tbody>
              {this.state.data_trimonthly && this.state.pageOfItemsTriMonth.map((item) =>
                <tr key={'tm'+item.cnt}>
                  <td>{item.cnt}</td>
                  <td>{item.datetime}</td>
                  <td>
                    {item.timepoint > 0 && item.timepoint}
                    {item.timepoint <= -9 && "Unscheduled"}
                  </td>
                  <td>
                  {item.pw18 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/pw18/tm/"+item.timepoint}
                    target="_blank">{item.pw18}</Link>}
                  {item.pw18 == '-' && '-'}
                  </td>
                  <td>
                  {item.covid > 0 && <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/covid/tm/"+item.timepoint}
                    target="_blank">COVID</Link>}
                  {item.covid == 0 && '-' }
                  </td>
                </tr>)}
              {this.state.data_trimonthly &&
                <tr className="pagination_block">
                  <td colSpan="5">
                    <Pagination items={this.state.data_trimonthly} pageSize={10} onChangePage={this.onChangePageTriMonth} />
                  </td>
                </tr>}
              </tbody>
            </table>
          </div>

          <div className="small-12 columns">
            <div className="title6">
              Six-month Assessments
            </div>
          </div>
          <div className="small-12 columns">
            <table className="table_data1">
              <thead className="table_head">
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Year</th>
                  <th>DERS</th>
                  <th>CSQ-8</th>
                </tr>
              </thead>
              <tbody>
              {this.state.data_sixmonthly && this.state.pageOfItemsSixMonth.map((item) =>
                <tr key={'y'+item.cnt}>
                  <td>{item.cnt}</td>
                  <td>{item.datetime}</td>
                  <td>{item.timepoint}</td>
                  <td>{item.ders18 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/ders18/sm/"+item.timepoint}
                    target="_blank">{item.ders18}</Link>}
                  {item.ders18 == '-' && '-'}
                  </td>
                  <td>{item.ders18 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/csq8/sm/"+item.timepoint}
                    target="_blank">{item.csq8}</Link>}
                  {item.csq8 == '-' && '-'}
                  </td>
                </tr>)}
              {this.state.data_sixmonthly &&
                <tr className="pagination_block">
                  <td colSpan="4">
                    <Pagination items={this.state.data_sixmonthly} pageSize={10} onChangePage={this.onChangePageSixMonth} />
                  </td>
                </tr>}
              </tbody>
            </table>
          </div>

          {this.state.data_yearly.length > 0 && <>
          <div className="small-12 columns">
            <div className="title6">
              Yearly Assessments
            </div>
          </div>
          <div className="small-12 columns">
            <table className="table_data1">
              <thead className="table_head">
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Year</th>
                  <th>DERS</th>
                </tr>
              </thead>
              <tbody>
              {this.state.data_yearly && this.state.pageOfItemsYear.map((item) =>
                <tr key={'y'+item.cnt}>
                  <td>{item.cnt}</td>
                  <td>{item.datetime}</td>
                  <td>{item.timepoint}</td>
                  <td>{item.ders18 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/ders18/y/"+item.timepoint}
                    target="_blank">{item.ders18}</Link>}
                  {item.ders18 == '-' && '-'}
                  </td>
                </tr>)}
              {this.state.data_yearly &&
                <tr className="pagination_block">
                  <td colSpan="4">
                    <Pagination items={this.state.data_yearly} pageSize={10} onChangePage={this.onChangePageYear} />
                  </td>
                </tr>}
              </tbody>
            </table>
          </div></>}

          <div className="small-12 columns">
            <div className="title6">
              Self Stigma
            </div>
          </div>
          <div className="small-12 columns">
            <table className="table_data1">
              <thead className="table_head">
                <tr>
                  <th>#</th>
                  <th>Due Date</th>
                  <th>SCCS</th>
                  <th>ISMI</th>
                </tr>
              </thead>
              <tbody>
              {this.state.self_stigma_assm.map((item, index) =>
                <tr key={'ss'+index}>
                  <td>{index+1}</td>
                  <td>{item.available_date_begin}</td>
                  <td><a href={"/admin/assessments/view_assessment_self_stigma/sccs/"+this.props.match.params.pid+"/"+item.id}>SCCS</a></td>
                  <td><a href={"/admin/assessments/view_assessment_self_stigma/ismi/"+this.props.match.params.pid+"/"+item.id}>ISMI</a></td>
                </tr>)}
              </tbody>
            </table>
          </div>

          {this.state.discharge_id > 0 && !this.state.start_discharge &&
          <div className="small-12 columns">
            <div className="title6">
              Discharge Assessments: <span className="no_bold">Incomplete</span>
            </div>
          </div>  }
          {this.state.start_discharge &&
          <div className="small-12 columns">
            <div className="title6">
              Discharge Assessments:
              <span className="no_bold"> Start {this.state.start_discharge} | Finish {this.state.end_discharge}</span>
            </div>
          </div>}
          {this.state.start_discharge &&
          <div className="small-12 columns">
            <table className="table_data1">
              <thead className="table_head">
                <tr>
                  <th>PHQ-9</th>
                  <th>ASRM</th>
                  <th>GAD-7</th>
                  <th>PSQI</th>
                  <th>WHO-5</th>
                  <th>CSQ-8</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                  {this.state.data_discharge.phq9 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/phq9/m/-2"}
                    target="_blank">{this.state.data_discharge.phq9}</Link>}
                  {this.state.data_discharge.phq9 == '-' && '-'}
                  </td>
                  <td>
                  {this.state.data_discharge.asrm != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/asrm/m/-2"}
                    target="_blank">{this.state.data_discharge.asrm}</Link>}
                  {this.state.data_discharge.asrm == '-' && '-'}</td>
                  <td>
                  {this.state.data_discharge.gad7 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/gad7/m/-2"}
                    target="_blank">{this.state.data_discharge.gad7}</Link>}
                  {this.state.data_discharge.gad7 == '-' && '-'}
                  </td>
                  <td>
                  {this.state.data_discharge.psqi != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/psqi/m/-2"}
                    target="_blank">{this.state.data_discharge.psqi}</Link>}
                  {this.state.data_discharge.psqi == '-' && '-'}
                  </td>
                  <td>
                  {this.state.data_discharge.who5 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/who5/m/-2"}
                    target="_blank">{this.state.data_discharge.who5}</Link>}
                  {this.state.data_discharge.who5 == '-' && '-'}
                  </td>
                  <td>
                  {this.state.data_discharge.csq8 != '-' &&
                  <Link to={"/admin/assessments/view_assessment/"+this.props.match.params.pid+"/csq8/m/-2"}
                    target="_blank">{this.state.data_discharge.csq8}</Link>}
                  {this.state.data_discharge.csq8 == '-' && '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>}

        </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AssmList)
